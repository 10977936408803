import React, { useState, useEffect } from 'react'
import { Button, Space, Modal } from 'antd'
import { DatePicker } from '../../components/dayjsToMoment'
import { addNewFrequency, clearNewSchedules, deleteSchedules, updateEndTime, updateStartTime } from '../../actions/bulkActions'
import { FrequencyView } from './frequencyView'
import { useDispatch, useSelector } from 'react-redux';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { parseSheet } from './helper';
import { sendBulkSchedule } from '../../elements/apis/controlApis';
import { toast } from 'react-toastify';
import { DeleteIcon, DeleteIconMCD, PlusIcon } from '../../assests/icon';
import { getValid } from '../../lib/softMapping';
import _isEmpty from 'lodash/isEmpty';
import { toastSuccess, toastError } from '../../toast/toast'
import DateRangeSeparator from "../../imgs/DateRangeSeparator.svg";
import DateRangeSeparatorSE from "../../imgs/DateRangeSeparatorSE.svg";
import DateRangeSeparatorMCD from "../../imgs/DateRangeSeparatorMCD.svg";
import CalendarIcon from "../../imgs/CalendarIcon.svg"
import CalendarIconSE from "../../imgs/CalendarIconSE.svg"
import CalendarIconMCD from "../../imgs/CalendarIconMCD.svg"
import { ArrowLeftIcon, ArrowRightIcon } from '../../assests/icon';
import { useTranslation } from '../../context/translationContext'
import { getCurrentUnitSystem } from '../../lib/function-helpers'

const { RangePicker } = DatePicker;
function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
}

export const SchedulesView = ({ config, addNewSchedule, nextPage, prevPage, isFirst, isLast, parentData }) => {
    const { getTranslation } = useTranslation();
    const [startDate, setStartDate] = useState(config.start_date !== null ? moment(config.start_date) : null)
    const [endDate, setEndDate] = useState(config.expiry_date !== null ? moment(config.expiry_date) : null)
    const [freqId, setFreqId] = useState(0)
    const [timeFrameId, setTimeFrameId] = useState(config.timeFrameId);
    const bulkReducer = useSelector(state => state.bulkReducer)
    const appPermissions = useSelector(state => state.appStatus.appPermissions)
	const dashboard = useSelector(state => state.dashboard);

    const dispatch = useDispatch();

    useEffect(() => {
        if (config.start_date !== null)
            setStartDate(config.start_date)
        else
            setStartDate(null)

        if (config.expiry_date !== null)
            setEndDate(config.expiry_date)
        else
            setEndDate(null)
        if (config.timeFrameId)
            setTimeFrameId(config.timeFrameId)
        else
            setTimeFrameId(null)

        // if (config.frequency.length >= freqId)
        // setFreqId(freqId - 1)
        // if (config.frequency.length === 0) {
        //     handleNewFreq()
        // }
    }, [config])

    const onChangeTime = (dateArray) => {
        if (dateArray[0])
            dispatch(updateStartTime({ key: timeFrameId, startDate: moment(dateArray[0]).format("YYYY-MM-DD") }))
        if (dateArray[1]) {
            dispatch(updateEndTime({ key: timeFrameId, endDate: moment(dateArray[1]).format("YYYY-MM-DD"), validationConstraint: bulkReducer.validationConstraint }))
        }
    }

    const handleNewFreq = () => {
        dispatch(addNewFrequency({ key: timeFrameId }))
        setFreqId(config.frequency.length)
    }

    const changeFreq = (freqId) => {
        setFreqId(freqId)
    }

    const onSubmit = async () => {
        let result = {};
        let tempSchedule = bulkReducer.schedules;
        let equipmentMapping = bulkReducer.selectedEquipmentsMapping;
        let validFeilds = getValid(bulkReducer.validationConstraint);

        for (let scheduleKey of Object.keys(tempSchedule)) {
            let weekly_schedules = []
            let date_schedules = []
            let freqData = tempSchedule[scheduleKey].frequency
            if (freqData && freqData.length) {
                for (let freqCount = 0; freqCount < freqData.length; freqCount++) {
                    let stateData = freqData[freqCount]['schedules'];
                    if (bulkReducer.filterData[`${scheduleKey}__${freqCount}`])
                        stateData = bulkReducer.filterData[`${scheduleKey}__${freqCount}`]
                    let schedules
                    if (parseSheet(stateData, equipmentMapping, validFeilds))
                        schedules = parseSheet(stateData, equipmentMapping, validFeilds)
                    else
                        return;
                    if (_isEmpty(schedules)) {
                        toast.error("Schedules cannot be empty", {icon: toastError()})
                        return
                    }
                    if (freqData[freqCount].isDate) {
                        let tempState = { date: freqData[freqCount].date, schedules };
                        date_schedules.push(tempState)
                    } else {
                        let tempState = { days_of_week: freqData[freqCount].days_of_week, schedules };
                        weekly_schedules.push(tempState)
                    }
                }
            }
            const { expiry_date, start_date } = tempSchedule[scheduleKey];
            Object.assign(result, { [scheduleKey]: { start_date, expiry_date, weekly_schedules, date_schedules } })
        }

        result = {"bulk_schedule": result, unit_type: getCurrentUnitSystem()}
        // console.log(result)
        // return;
        let resp = await sendBulkSchedule(result, appPermissions.isDemoUser);
        if (resp && resp.deployment_status) {
            if (resp.deployment_status === "success") {
                toast.success(resp.message, {icon: toastSuccess()})
                // await init(equipment_name)
                dispatch(clearNewSchedules())
                // cancel()/
            }
            else
                toast.error(resp.message ||  "Could not fetch data, Try sometime later...", {icon: toastError()})
        } 
    }
    const deleteSchedule = () => {
        let config = {};
        config = {
            title: getTranslation('Are you Sure ?'),
            icon: null,
            content: getTranslation("This schedule will be delete forever, this process is not reversible."),
            okText: getTranslation("Delete"),
            className: `cs-modal controls-action-modal ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}`,
            onOk: () => dispatch(deleteSchedules({ key: timeFrameId }))
        };
        Modal.confirm(config)
    }

    return timeFrameId !== null ? <div className="display-column">
        <div className="d jc-sb ai-center">
            <Space>
                <RangePicker 
                popupClassName={!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
					dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}
                suffixIcon={<img src={!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider' ? CalendarIconSE : dashboard?.customerTheme === 'mcdonalds' ? CalendarIconMCD : CalendarIcon} alt="calendar-icon" />}
                separator={
                        <img
                            src={
                            !_isEmpty(dashboard && dashboard?.customerTheme) &&
                            dashboard?.customerTheme === "schneider"
                                ? DateRangeSeparatorSE : 
                                dashboard?.customerTheme === "mcdonalds"
                                ? DateRangeSeparatorMCD
                                : DateRangeSeparator
                            }
                            alt='ant-date-range-separator'
                        />
                    } format="YYYY-MM-DD" disabledDate={disabledDate}
                    value={(startDate !== null && endDate !== null) ? [moment(startDate), moment(endDate)] : null}
                    onChange={onChangeTime} allowClear={false} className="red" />
                {timeFrameId !== "time_frame1" && <Button className="red-light-button delete-btn-icon"
                    onClick={deleteSchedule} icon={(!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'mcdonalds')  ? <DeleteIconMCD /> : <DeleteIcon />} />}
            </Space>
            <div className="d ai-center pagination ml-20">
                <Button size={"small"} className="yellow-button mr-5"
                    disabled={isFirst} onClick={prevPage} ><ArrowLeftIcon style={{ marginTop: '4px' }} /></Button>
                <div className="">{getTranslation("Time Frame")} {config.timeFrameId.slice(-1)}</div>
                <Button size={"small"} className="ml-5 mr-5 yellow-button" onClick={nextPage} disabled={isLast} ><ArrowRightIcon style={{ marginTop: '4px' }} /></Button>
                {/* <p className="" style={{ margin: 0, padding: 0 }}> <b>1 - 50</b> of 100 </p> */}
            </div>
        </div>
        {(startDate && endDate) ? <div className="display-column wrapper mt-20">
            <FrequencyView allSchedules={parentData} startDate={startDate} endDate={endDate} freqData={config.frequency[freqId]} updateSelectedFre={(data) => setFreqId(data)} freqId={freqId} timeFrameId={timeFrameId} />
            <div className="d jc-sb mt-10">
                {/* below Button */}
                <div className="d ai-center">
                    {config.frequency.map((data, id) =>
                        <div onClick={() => setFreqId(id)}
                            key={id} className={" ml-5 freq-indicator" + (freqId === id ? "-active" : "")}>{getTranslation("Freq")} {id + 1}</div>
                    )}
                    <Button className="red-light-button ml-5" onClick={() => handleNewFreq()} ><PlusIcon className="plus-icon-color" /></Button>
                    {/* <div className="d ai-center pagination ml-20"> */}
                    {/* <Button size={"small"} className="yellow-button" ><LeftOutlined /></Button> */}
                    {/* <Button size={"small"} className="ml-5 mr-5 yellow-button"><RightOutlined /></Button> */}
                    {/* <p className="" style={{ margin: 0, padding: 0 }}> <b>1 - 50</b> of 100 </p> */}
                    {/* </div> */}
                </div>
                <div className="d ai-center">
                    <Button className="red-light-button" onClick={() => addNewSchedule()} >{getTranslation("New Time Frame")}</Button>
                    <Button className="red-button ml-5" onClick={() => onSubmit()} >{getTranslation("Submit Schedule(s)")}</Button>
                </div>
            </div>
        </div> : <div className="wrapper  d color-grey jc-c ai-center mt-20" style={{ height: '80vh', textAlign: 'center' }}>
            {getTranslation("Please select start and end date")}
        </div>}
    </div> : <div></div>
}