import React, { useEffect, useRef, useState } from 'react'
import { Select } from 'antd'
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import { useDispatch, useSelector } from 'react-redux';
import { getStreamFeatures, getClientEquipmentMapping } from '../../elements/apis/controlApis';
import { setUserPermissions } from '../../actions/appActions';
import DownArrowOutlined from "../../imgs/DownArrowOutlined.svg"
import { useTranslation } from '../../context/translationContext';
import { setSiteNameCPM } from '../../actions/cpmActions';
import { sortAccToPriorityInstantControl } from '../../lib/softMapping';
import { toast } from 'react-toastify';
import { toastError } from '../../toast/toast';
import { getCurrentUnitSystem } from '../../lib/function-helpers';

export const SelectCPMSite = () => {
    const { getTranslation } = useTranslation();
    const [siteEquipmentTypeMapping, setSiteEquipmentTypeMapping] = useState({});
    const [allEquipmentsData, setAllEquipmentsData] = useState([])
    const [equipmentTypesData, setEquipmentTypesData] = useState({})
    const [selectedSite, setSelectedSite] = useState(null)

    const dispatch = useDispatch()
    const cpmReducer = useSelector(state => state.cpmReducer)
    const appPermissions = useSelector(state => state.appStatus.appPermissions)
    const siteSelect = useRef();

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (cpmReducer.siteDisplayName){
            setSelectedSite(cpmReducer.siteDisplayName)
        }
        else
            setSelectedSite(null)
    }, [cpmReducer.siteDisplayName, cpmReducer.equipmentType])

    const unFocus = (element) => {
        element.blur()
    }

    const init = async () => {
        try{
            let res;
            if(appPermissions['clientEquipmentMapping'] && appPermissions['clientEquipmentMapping']['data'])
                res = appPermissions['clientEquipmentMapping']
            else{
                res = await getClientEquipmentMapping()
                dispatch(setUserPermissions(res))
            }
            if (res && res.data && res.data.clientSet.edges) {
                let allEquipments = []
                let equipmentTypes = {}
                let tempClientEquipmentTypeMapping = {}
    
                res.data.clientSet.edges.map(data =>
                    allEquipments.push(...data.node.equipments.edges.map(equipmentData => {
                        Object.assign(equipmentTypes, { [equipmentData.node.type.name]: equipmentData.node.type.displayName })
                        return {
                            ...equipmentData.node, siteDisplayName: data.node.displayName,
                            siteName: data.node.clientName, type: equipmentData.node.type.name,cpmEnabled:data.node.cpmEnabled
                        }
                    }
                    ))
                )
                for(let index = 0 ; index < allEquipments.length ; index++){
                    let obj = allEquipments[index]
                    let requiredEquipmentTypeDisplayName = equipmentTypes[obj['type']]
                    let newSiteMapping = {
                        'displayName': requiredEquipmentTypeDisplayName,
                        'name': obj['type']
                    }
                    if(!obj['cpmEnabled']){
                        continue;
                    }
                    if(tempClientEquipmentTypeMapping.hasOwnProperty(obj['siteDisplayName'])){
                        let oldSiteEquipmentDataMapping = tempClientEquipmentTypeMapping[obj['siteDisplayName']]
                        let i;
                        for(i = 0 ; i < oldSiteEquipmentDataMapping.length ; i++){
                            let equipmentData = oldSiteEquipmentDataMapping[i];
                            if(equipmentData['name'] === newSiteMapping['name']) break;
                        }
                        if(i === oldSiteEquipmentDataMapping.length)
                            oldSiteEquipmentDataMapping.push(newSiteMapping)
                        tempClientEquipmentTypeMapping[obj['siteDisplayName']] = oldSiteEquipmentDataMapping
                    } else {
                        tempClientEquipmentTypeMapping[obj['siteDisplayName']] = [newSiteMapping]
                    }
                }
                setSiteEquipmentTypeMapping(tempClientEquipmentTypeMapping)
                setAllEquipmentsData(allEquipments)
                setEquipmentTypesData(equipmentTypes)
            }
        }catch(e){
            console.log(e)
        }
    }


    const updateSiteRedux = async (siteName) => {
        let siteID;
        allEquipmentsData.map(obj => {
            if(obj.siteDisplayName === siteName)
                siteID = obj.siteName
        })
        
        const streamResponse = await getStreamFeatures({client_name: siteID, unit_type: getCurrentUnitSystem()});
        const streamData = {}; 
        if(!Object.keys(streamResponse?.data)?.length){
            toast.error("No equipment configured with CPM for this site. Please update your equipment settings.", {icon: toastError()})
        }
        else{
            Object.keys(streamResponse?.data).forEach((equipmentType)=>{
                const equipmentStreams = streamResponse?.data[equipmentType];
                let equipmentTypeDisplayName = equipmentTypesData[equipmentType] || equipmentType
                let widgets = [];
                let validationConstraint = {}
                if (equipmentStreams) {
                    validationConstraint = equipmentStreams
                    for(let index = 0 ; index < Object.keys(equipmentStreams).length ; index++){
                        let reqData = Object.keys(equipmentStreams)[index]
                        let dataType = equipmentStreams[reqData]['type']
                        let dataUnit = equipmentStreams[reqData]['unit']
                        let dataProperties = equipmentStreams[reqData]['properties']
                        let obj = {
                            'title': reqData,
                            'name': reqData.replace(/ |\//g, "_"),
                            'type': dataType,
                            'unit': dataUnit,
                            'properties': dataProperties,
                            'value': null
                        }
                        widgets.push(obj)
                    }
                }
        
                streamData[equipmentType] = {
                    equipmentType,
                    equipmentTypeDisplayName,
                    siteDisplayName: siteName,
                    siteName: siteID,
                    widgets: sortAccToPriorityInstantControl(widgets, equipmentType),
                    validationConstraint
                }

            })
        }
         dispatch(setSiteNameCPM({
            siteDisplayName: siteName,
            siteName: siteID,
            siteStreamData:streamData,
        }))
    }

    const onChangeSites = siteName => {
        if (siteName) {
            updateSiteRedux(siteName)
            unFocus(siteSelect.current)
            setSelectedSite(siteName)
        }
    };

    return (
        <div className="d jc-sb hierarchy-context mb-10 mt-20" style={{margin: '15px 0'}}>
            <div>
                <span id={"custom-select-label"}>{getTranslation("Site")}</span>
                <Select
                    ref={siteSelect}
                    showSearch
                    placeholder={getTranslation("Select Site")}
                    onChange={onChangeSites}
                    suffixIcon={
                    <img src={DownArrowOutlined} alt="down-arrow" width={"14px"} />
                    }
                    value={selectedSite}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ marginRight: "0" }}
                >
                    {!_isEmpty(siteEquipmentTypeMapping) &&
                    Object.keys(siteEquipmentTypeMapping).map((site) => (
                        <Select.Option key={site} value={site}>
                        {site}
                        </Select.Option>
                    ))}
                </Select>
            </div>
           
        </div>
    )
}
