import React, { useEffect, useRef, useState } from 'react'
import { Button, Checkbox, Divider, Select } from 'antd'
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import { useDispatch, useSelector } from 'react-redux';
import { getBulkContolStreamFeatures, getClientEquipmentMapping, getEquipmentTypeStreamFeatures } from '../../elements/apis/controlApis';
import { clearNewSchedules, updateMetaInfo, resetState } from '../../actions/bulkActions';
import { setUserPermissions } from '../../actions/appActions';
import DownArrowOutlined from "../../imgs/DownArrowOutlined.svg"
import { useTranslation } from '../../context/translationContext';
import { getCurrentUnitSystem } from '../../lib/function-helpers';


const DEFAULT_OPTION = 'Select all'

export const SelectEquipmentType = () => {
    const { getTranslation } = useTranslation();
    const [allEquipments, setAllEquipments] = useState([]);
    const filterData = useRef(null);
    const [filters, setFilters] = useState({ type: [], regions: [], sites: {} });
    const [selectedFilters, setSelectedFilters] = useState({ type: null, regions: [], sites: [] });
    const [openFilter,setOpenFilter] = useState(''); 

    const dispatch = useDispatch()
    const bulkReducer = useSelector(state => state.bulkReducer)
    const appPermissions = useSelector(state => state.appStatus.appPermissions)
    const dashboard = useSelector(state => state.dashboard);

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (bulkReducer.selectedType && bulkReducer.selectedRegions && bulkReducer.selectedSites )
            setSelectedFilters({type: bulkReducer.selectedType, regions: bulkReducer.selectedRegions, sites: bulkReducer.selectedSites })
    }, [bulkReducer.selectedType])

    const init = async () => {
      try {
        let res;
        if (appPermissions['clientEquipmentMapping'] && appPermissions['clientEquipmentMapping']['data'])
          res = appPermissions['clientEquipmentMapping'];
        else {
          res = await getClientEquipmentMapping();
          dispatch(setUserPermissions(res));
        }

        if (res && res.data && res.data.clientSet.edges) {
          let allEquipments = [];
          let filterDataJson = {};

          res.data.clientSet.edges.forEach((data) =>
            data.node.equipments.edges.forEach((equipmentData) => {

              //filter structure
              let region = data.node.siteMetadata?.Region || 'Not defined';
              if (!!filterDataJson[equipmentData.node.type.name]) {
                if (!!filterDataJson[equipmentData.node.type.name]?.regions?.[region]) {
                  if (!filterDataJson[equipmentData.node.type.name]?.regions?.[region]?.[data.node.clientName]) {
                    Object.assign(filterDataJson[equipmentData.node.type.name]?.regions?.[region], {
                      [data.node.clientName]: data.node.displayName,
                    });
                  }
                } else {
                  Object.assign(filterDataJson[equipmentData.node.type.name]?.regions, {
                    [region]: { [data.node.clientName]: data.node.displayName },
                  });
                }
              } else {
                filterDataJson[equipmentData.node.type.name] = {
                  regions: {
                    [region]: { [data.node.clientName]: data.node.displayName },
                  },
                  displayName: equipmentData.node.type?.displayName || equipmentData.node.type?.name,
                };
              }

              //equipment data for table
              allEquipments.push({
                ...equipmentData.node,
                siteDisplayName: data.node.displayName,
                siteName: data.node.clientName,
                type: equipmentData.node.type.name,
                region: data.node.siteMetadata?.Region || "Not defined",
              });

            })
          );

          filterData.current = filterDataJson;
          setAllEquipments(allEquipments);
          
        if (bulkReducer.selectedType && bulkReducer.selectedRegions && bulkReducer.selectedSites) {
          setFilters({
            type: Object.keys(filterDataJson),
            regions: Object.keys(filterDataJson[bulkReducer.selectedType]?.regions),
            sites: bulkReducer.selectedRegions.reduce((acc, cur) => {
              return { ...acc, ...filterData.current?.[bulkReducer.selectedType]?.regions?.[cur] };
            }, {}),
          });
        } else {
          setFilters({ ...filters, type: Object.keys(filterDataJson) });
        }

        }
      } catch (e) {
        console.log(e);
      }
    };

    const onChangeTypes = (selectedType) => {
      if (selectedType) {
        setFilters({ ...filters, regions: Object.keys(filterData.current[selectedType]?.regions), sites: [] });
        setSelectedFilters({ ...selectedFilters, type: selectedType, regions: [], sites: [] });
      }
      dispatch(resetState());
    };
    

    const onChangeRegions = (selectedRegions) => {
      if (selectedRegions) {
        let newRegions;
        let isSelectAllInSelectedRegions = selectedRegions.includes(DEFAULT_OPTION);
        let isSelectAllInExistingSelectedRegions = selectedFilters.regions.includes(DEFAULT_OPTION);

        if (isSelectAllInExistingSelectedRegions && !isSelectAllInSelectedRegions) {
          newRegions = [];
        } else if (!isSelectAllInExistingSelectedRegions && isSelectAllInSelectedRegions) {
          newRegions = [DEFAULT_OPTION, ...Object.keys(filterData.current[selectedFilters?.type]?.regions)];
        } else if (isSelectAllInExistingSelectedRegions && isSelectAllInSelectedRegions) {
          newRegions = selectedRegions.filter((item) => item != DEFAULT_OPTION);
        } else {
          newRegions = selectedRegions;
        }

        setFilters({ ...filters, sites: [] });
        setSelectedFilters({ ...selectedFilters, regions: newRegions, sites: [] });
      }
      dispatch(resetState());
    };

    const onChangeSites = (selectedSites) => {
      if (selectedSites) {
        let newSites;
        let isSelectAllInSelectedSites = selectedSites.includes(DEFAULT_OPTION);
        let isSelectAllInExistingSelectedSites = selectedFilters.sites.includes(DEFAULT_OPTION);
        if (isSelectAllInExistingSelectedSites && !isSelectAllInSelectedSites) {
          newSites = [];
        } else if (!isSelectAllInExistingSelectedSites && isSelectAllInSelectedSites) {
          newSites = Object.keys(
            selectedFilters.regions.reduce(
              (acc, cur) => {
                return { ...acc, ...filterData.current?.[selectedFilters?.type]?.regions?.[cur] };
              },
              { [DEFAULT_OPTION]: DEFAULT_OPTION }
            )
          );
        } else if (isSelectAllInExistingSelectedSites && isSelectAllInSelectedSites) {
          newSites = selectedSites.filter((item) => item != DEFAULT_OPTION);
        } else {
          newSites = selectedSites;
        }
        setSelectedFilters({ ...selectedFilters, sites: newSites });
      }

      dispatch(resetState());
      dispatch(clearNewSchedules());
    };

    const onDropdownVisibleChange = (filterName) =>{
        setOpenFilter(filterName === openFilter ? '' : filterName);
    }

    const onRegionApply = () => {
      setFilters({
        ...filters,
        sites: selectedFilters.regions.reduce((acc, cur) => {
          return { ...acc, ...filterData.current?.[selectedFilters?.type]?.regions?.[cur] };
        }, {}),
      });
      setOpenFilter('');
    
    };

    const onSiteApply = () => {
      if(!selectedFilters.sites?.length){
        return;
      }

      let equipmentMapping = allEquipments.filter((equipmentData) => {
        return (
          equipmentData.type === selectedFilters.type &&
          selectedFilters.regions.includes(equipmentData?.region) &&
          selectedFilters.sites.includes(equipmentData.siteName)
        );
      });
      
      updateEqupmentRedux(selectedFilters.type, selectedFilters.regions, selectedFilters.sites, equipmentMapping);
      setOpenFilter('');
    
    };

    const onClearSites = () =>{
        setSelectedFilters({ ...selectedFilters, sites: [] });
        dispatch(resetState())
    }
    const onClearRegions = () =>{
        setSelectedFilters({ ...selectedFilters, regions: [], sites: [] });
        dispatch(resetState())
    }

    const updateEqupmentRedux = async (equipmentType, equipmentRegions, equipmentSites, equipmentMapping) => {
      dispatch(clearNewSchedules());
      let widgets = ['Time'];
      let validationConstraint = {};
      let res = await getBulkContolStreamFeatures({ equipmentType, client_name: equipmentSites.filter((item) => item != DEFAULT_OPTION), unit_type: getCurrentUnitSystem() });
      if (res && res.data) {
        validationConstraint = res.data;
        widgets = [...widgets, ...Object.keys(res.data)];
      }
      dispatch(
        updateMetaInfo({
          equipmentType,
          equipmentRegions,
          equipmentSites,
          equipmentMapping,
          widgets,
          validationConstraint,
        })
      );
    };

    return (
      <div className="d jc-sb hierarchy-context mb-10">
        <div>
          <span id={'custom-select-label'}>{getTranslation('Type')}</span>
          <Select
            showSearch
            placeholder={getTranslation('Select Type')}
            onChange={onChangeTypes}
            value={selectedFilters.type}
            suffixIcon={<img src={DownArrowOutlined} alt="down-arrow" width={'14px'} />}
            optionFilterProp="children"
          >
            {!_isEmpty(filters.type) &&
              filters.type.map((item) => (
                <Select.Option key={item} value={item}>
                  {getTranslation(filterData.current[item]?.displayName)}
                </Select.Option>
              ))}
          </Select>
        </div>

        <div>
          <span id={'custom-select-label'}>{getTranslation('Region')}</span>
          <Select
            open={openFilter === 'regions'}
            onDropdownVisibleChange={() => onDropdownVisibleChange('regions')}
            placeholder={getTranslation('Select Regions')}
            mode="multiple"
            maxTagCount={'responsive'}
            maxTagTextLength={10}
            onChange={onChangeRegions}
            tagRender={(props) => props.value !== DEFAULT_OPTION && <div className="select-box-tag-render-item">{`${props.value} `}</div>}
            value={selectedFilters.regions}
            suffixIcon={<img src={DownArrowOutlined} alt="down-arrow" width={'14px'} />}
            popupClassName={`${
              !_isEmpty(dashboard && dashboard?.customerTheme) &&
              ((dashboard?.customerTheme === 'schneider' && ' SE-theme-color') || (dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color'))
            }`}
            dropdownRender={(menu, props) => {
              return (
                <>
                  {menu}
                  <Divider className="filter-footer-divider" />
                  <div className="filter-footer-container">
                    <Button className={`red-button filter-button`} onClick={onRegionApply}>
                      {getTranslation('Apply')}
                    </Button>
                    <Button type="default" onClick={onClearRegions} className="outlined filter-button">
                      {getTranslation('Clear')}
                    </Button>
                  </div>
                </>
              );
            }}
          >
            {!_isEmpty(filters.regions) &&
              [DEFAULT_OPTION, ...filters.regions].map((item) => {
                return (
                  <Select.Option key={item} value={item} className={'filter-select-option-container'}>
                    <Checkbox checked={selectedFilters.regions.includes(item)} className="pointer-events-none">
                      {getTranslation(item)}
                    </Checkbox>
                  </Select.Option>
                );
              })}
          </Select>
        </div>

        <div>
          <span id={'custom-select-label'}>{getTranslation('Sites')}</span>
          <Select
            open={openFilter === 'sites'}
            onDropdownVisibleChange={() => onDropdownVisibleChange('sites')}
            showSearch
            placeholder={getTranslation('Select Sites')}
            mode="multiple"
            maxTagCount={'responsive'}
            maxTagTextLength={10}
            onChange={onChangeSites}
            value={selectedFilters.sites}
            suffixIcon={<img src={DownArrowOutlined} alt="down-arrow" width={'14px'} />}
            popupClassName={`${
              !_isEmpty(dashboard && dashboard?.customerTheme) &&
              ((dashboard?.customerTheme === 'schneider' && ' SE-theme-color') || (dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color'))
            }`}
            optionFilterProp="key"
            tagRender={(props) =>
              props.value !== DEFAULT_OPTION && <div className="select-box-tag-render-item">{`${filters?.sites[props.value]} `}</div>
            }
            dropdownRender={(menu) => {
              return (
                <>
                  {menu}
                  <Divider className="filter-footer-divider" />
                  <div className="filter-footer-container">
                    <Button className={`red-button filter-button`} onClick={onSiteApply}>
                      {getTranslation('Apply')}
                    </Button>
                    <Button type="default" onClick={onClearSites} className="outlined filter-button">
                      {getTranslation('Clear')}
                    </Button>
                  </div>
                </>
              );
            }}
          >
            {!_isEmpty(Object.keys(filters.sites)) &&
              [DEFAULT_OPTION, ...Object.keys(filters.sites)].map((clientName) => {
                return (
                  <Select.Option key={filters.sites[clientName]|| clientName } value={clientName} className={'filter-select-option-container'}>
                    <Checkbox checked={selectedFilters.sites.includes(clientName)} className="pointer-events-none">
                      {getTranslation(filters.sites[clientName] || clientName)}
                    </Checkbox>
                  </Select.Option>
                );
              })}
          </Select>
        </div>
      </div>
    );
}
