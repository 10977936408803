// apis for fetching data from server
import { toast } from "react-toastify";
import axiosAPI, { encodeParams } from "../../lib/api-helpers";
import { toastError } from "../../toast/toast";
import { CUSTOMER_NAME_CHANGE } from "../../lib/constants";

export const getStreamsForEquipment = async config => {
	try {
		const response = await axiosAPI.post("/control/getStreamsForEquipment", JSON.stringify(config))
		return response['data']
	} catch (e) {
		toast.error("Could not fetch data, Try after 2-3 minutes...", {icon: toastError()});
	}
};

export const getClientEquipmentMapping = async () => {
	try {
		const response = await axiosAPI.get("/control/getClientEquipmentMapping")
		return response['data']
	} catch (e) {
		toast.error(e.response.data.message || "Could not fetch data, Try after 2-3 minutes...", {icon: toastError()});
	}
}

export const getClientEquipmentMappingFilterApplied = async config => {
	try {
		const response = await axiosAPI.post("/control/getClientEquipmentMappingFilterApplied", JSON.stringify(config))
		return response['data']
	} catch (e) {
		toast.error(e.response.data.message || "Could not fetch data, Try after 2-3 minutes...", {icon: toastError()});
	}
};

export const getAllEquipmentSchedulesStatus = async config => {
	try{
		const response = await axiosAPI.post("/control/getAllEquipmentSchedulesStatus", JSON.stringify(config))
		return response['data']
	}catch(e){
		toast.error(e.response.data.message || "Could not fetch data, Try after 2-3 minutes...", {icon: toastError()});
	}
};

export const getExpectedSchedule = async config => {
	try {
		const response = await axiosAPI.post("/control/getExpectedSchedule", JSON.stringify(config))
		return response['data']
	} catch (e) {
		toast.error(e.response.data.message || "Could not fetch data, Try after 2-3 minutes...", {icon: toastError()});
	}
};

export const sendSchedule = async (config, demoUserCheck) => {
	try {
		if(demoUserCheck)
			return { status: 1, deployment_status: "success", message: "Controls successfully deployed" }
		const response = await axiosAPI.post("/control/sendSchedule", JSON.stringify(config))
		return response['data']
	} catch (e) {
		toast.error(e.response.data.validation_error || e.response.data.message || "Could not fetch data, Try after 2-3 minutes...", {icon: toastError()});
	}
};

export const getStreamsForEquipmentForOverride = async config => {
	try {
		const response = await axiosAPI.post("/control/getStreamsForEquipmentForOverride", JSON.stringify(config))
		return response['data']
	} catch (e) {
		toast.error(e.response.data.message || "Could not fetch data, Try after 2-3 minutes...", {icon: toastError()});
	}
};

export const deleteControls = async (config, demoUserCheck) => { 
	try {
		if(demoUserCheck)
			return { data: "{\"success\": [\"Deleted\"], \"failure\": []}" }
		const response = await axiosAPI.post("/control/deleteControls", JSON.stringify(config))
		return response['data']
	} catch (e) {
		toast.error(e.response.data.message || "Could not fetch data, Try after 2-3 minutes...", {icon: toastError()});
	}
};

export const getBulkContolStreamFeatures = async (config) => {
	try {
		const response = await axiosAPI.post("/control/getBulkContolStreamFeatures", JSON.stringify(config))
		return response['data']
	} catch (e) {
		toast.error(e.response.data.message || "Could not fetch data, Try after 2-3 minutes...", {icon: toastError()});
	}
};

export const sendBulkSchedule = async (config, demoUserCheck) => {
	try {
		if(demoUserCheck)
			return { status: 1, deployment_status: "success", message: "Controls successfully deployed" }
		const response = await axiosAPI.post("/control/sendBulkSchedule", JSON.stringify(config))
		return response['data']
	} catch (e) {
		toast.error(e.response.data.validation_error || e.response.data.message || "Could not fetch data, Try after 2-3 minutes...", {icon: toastError()});
	}
};

export const getEquipmentTypeStreamFeatures = async config => {
	try {
		const response = await axiosAPI.post("/control/getEquipmentTypeStreamFeatures", JSON.stringify(config))
		return response['data']
	} catch (e) {
		toast.error(e.response.data.message || "Could not fetch data, Try after 2-3 minutes...", {icon: toastError()});
	}
};
 
export const getStreamFeatures = async (params) => {
	try {
		const response = await axiosAPI.get("/control/getStreamFeatures?" + encodeParams(params))
		return response['data']
	} catch (e) {
		toast.error(e.response.data.message || "Could not fetch data, Try after 2-3 minutes...", {icon: toastError()});
	}
}; 

export const downloadEquipmentSchedules = async config => {
	try {
		const response = await axiosAPI.post("/control/downloadEquipmentSchedules", JSON.stringify(config), {responseType: 'blob'})
		if (response.status !== 200) {
			const body = response['data']
			toast.error(body.message, {icon: toastError()});
		} else {
			let a = document.createElement('a');
			a.href = window.URL.createObjectURL(response['data']);
			a.download = `${config.isDemoUser ? CUSTOMER_NAME_CHANGE[config.customer_name] : config.customer_name}__${config.start_date} - ${config.end_date}__schedules.xlsx`;
			a.style.display = 'none';
			document.body.appendChild(a);
			a.click();
			return true;
		}
		return false;
	} catch (e) {
		toast.error(e.response.data.message || "Could not fetch data, Try after 2-3 minutes...", {icon: toastError()});
	}
};

export const getLiveStatusV2 = async params => {
	try {
		const response = await axiosAPI.get(`/control/liveStatusV2?` + encodeParams(params))
		return response['data']
	} catch (e) {
		toast.error(e.response.data.message || "Could not fetch data, Try after 2-3 minutes...", {icon: toastError()});
	}
};

export const sendInstantControl = async (params, demoUserCheck) => { 
	try {
		if(demoUserCheck)
			return { status: 1 }
		const response = await axiosAPI.post("/control/setInstantControl", JSON.stringify({ data: { ...params } }))
		return response['data']
	} catch (e) {
		toast.error(e.response.data.message || "No changes were saved, please try after 2-3 minutes", {icon: toastError()});
	}
};

export const deleteInstantControl = async (params, demoUserCheck) => {
	try {
		if(demoUserCheck)
			return { status: 1 }
		const response = await axiosAPI.delete("/control/deleteInstant", { data: { data: {...params }}})
		return response['data']
	} catch (e) {
		toast.error(e.response.data.message || "No changes were saved, please try after 2-3 minutes", {icon: toastError()});
	}
};

export const getSiteLiveStatus = async params => {
	try {
		const response = await axiosAPI.get(`/control/siteLiveStatus?` + encodeParams(params))
		return response['data']
	} catch (e) {
		toast.error(e.response.data.message || "Could not fetch data, Try after 2-3 minutes...", {icon: toastError()});
	}
};

export const getEquipmentTypeStreamFeaturesInstantControl = async params => {
	try {
		const response = await axiosAPI.get(`/control/getEquipmentTypeStreamFeaturesInstantControl?` + encodeParams(params))
		return response['data']
	} catch (e) {
		toast.error(e.response.data.message || "Could not fetch data, Try after 2-3 minutes...", {icon: toastError()});
	}
};
