import React, { useEffect, useRef, useState } from 'react'
import { Select } from 'antd'
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import { useDispatch, useSelector } from 'react-redux';
import { getStreamFeatures, getClientEquipmentMapping, getEquipmentTypeStreamFeaturesInstantControl } from '../../elements/apis/controlApis';
import { clearEquipment, setEquipmentType } from '../../actions/instantControlActions';
import { setSiteNameControlStatus } from '../../actions/controlStatusActions';
import { sortAccToPriorityInstantControl } from '../../lib/softMapping';
import { setUserPermissions } from '../../actions/appActions';
import DownArrowOutlined from "../../imgs/DownArrowOutlined.svg"
import { useTranslation } from '../../context/translationContext';
import { getCurrentUnitSystem } from '../../lib/function-helpers';

export const SelectSiteEquipmentType = (props) => {
    const { getTranslation } = useTranslation();
    const [siteEquipmentTypeMapping, setSiteEquipmentTypeMapping] = useState({});
    const [allEquipmentsData, setAllEquipmentsData] = useState([])
    const [equipmentTypesData, setEquipmentTypesData] = useState({})
    const [selectedSite, setSelectedSite] = useState(null)
    const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);
    const [equipmentsTypeData, setEquipmentsTypeData] = useState([]);

    const dispatch = useDispatch()
    const instantControlReducer = useSelector(state => state.instantControlReducer)
    const controlStatusReducer = useSelector(state => state.controlStatusReducer)
    const appPermissions = useSelector(state => state.appStatus.appPermissions)
    const siteSelect = useRef();
    const equipemntSelect = useRef();

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if(props.controlStatus){
            if (controlStatusReducer.siteDisplayName)
                setSelectedSite(controlStatusReducer.siteDisplayName)
            else
                setSelectedSite(null)
        }else{
            if (instantControlReducer.siteDisplayName){
                setSelectedSite(instantControlReducer.siteDisplayName)
                setEquipmentsTypeData(instantControlReducer.equipmentsTypeDataMapping)
            }else{
                setSelectedSite(null)
                setEquipmentsTypeData([])
            }
            if(instantControlReducer.equipmentType)
                setSelectedEquipmentType(instantControlReducer.equipmentType)
            else
                setSelectedEquipmentType(null)
        }
    }, [instantControlReducer.siteDisplayName, instantControlReducer.equipmentType, controlStatusReducer.siteDisplayName, controlStatusReducer.equipmentType, props.controlStatus])

    const unFocus = (element) => {
        element.blur()
    }

    const init = async () => {
        try{
            let res;
            if(appPermissions['clientEquipmentMapping'] && appPermissions['clientEquipmentMapping']['data'])
                res = appPermissions['clientEquipmentMapping']
            else{
                res = await getClientEquipmentMapping()
                dispatch(setUserPermissions(res))
            }
            if (res && res.data && res.data.clientSet.edges) {
                let allEquipments = []
                let equipmentTypes = {}
                let tempClientEquipmentTypeMapping = {}
    
                res.data.clientSet.edges.map(data =>
                    allEquipments.push(...data.node.equipments.edges.map(equipmentData => {
                        Object.assign(equipmentTypes, { [equipmentData.node.type.name]: equipmentData.node.type.displayName })
                        return {
                            ...equipmentData.node, siteDisplayName: data.node.displayName,
                            siteName: data.node.clientName, type: equipmentData.node.type.name
                        }
                    }
                    ))
                )
                for(let index = 0 ; index < allEquipments.length ; index++){
                    let obj = allEquipments[index]
                    let requiredEquipmentTypeDisplayName = equipmentTypes[obj['type']]
                    let newSiteMapping = {
                        'displayName': requiredEquipmentTypeDisplayName,
                        'name': obj['type']
                    }
                    if(tempClientEquipmentTypeMapping.hasOwnProperty(obj['siteDisplayName'])){
                        let oldSiteEquipmentDataMapping = tempClientEquipmentTypeMapping[obj['siteDisplayName']]
                        let i;
                        for(i = 0 ; i < oldSiteEquipmentDataMapping.length ; i++){
                            let equipmentData = oldSiteEquipmentDataMapping[i];
                            if(equipmentData['name'] === newSiteMapping['name']) break;
                        }
                        if(i === oldSiteEquipmentDataMapping.length)
                            oldSiteEquipmentDataMapping.push(newSiteMapping)
                        tempClientEquipmentTypeMapping[obj['siteDisplayName']] = oldSiteEquipmentDataMapping
                    } else {
                        tempClientEquipmentTypeMapping[obj['siteDisplayName']] = [newSiteMapping]
                    }
                }
                // console.log({allEquipments, equipmentTypes, tempClientEquipmentTypeMapping});
                setSiteEquipmentTypeMapping(tempClientEquipmentTypeMapping)
                setAllEquipmentsData(allEquipments)
                setEquipmentTypesData(equipmentTypes)
            }
        }catch(e){
            console.log(e)
        }
    }

    const updateEquipmentTypeRedux = async (equipmentType) => {
        dispatch(clearEquipment())
        let widgets = [];
        let validationConstraint = {}
        let siteName;
        let equipmentTypeDisplayName = equipmentTypesData[equipmentType]
        allEquipmentsData.map(obj => {
            if(obj.siteDisplayName === selectedSite)
                siteName = obj.siteName
        })
        let res = await getStreamFeatures({ equipment_type: equipmentType, client_name: siteName, unit_type: getCurrentUnitSystem() })
        if (res && res.data) {
            validationConstraint = res.data
            for(let index = 0 ; index < Object.keys(res.data).length ; index++){
                let reqData = Object.keys(res.data)[index]
                let dataType = res.data[reqData]['type']
                let dataUnit = res.data[reqData]['unit']
                let dataProperties = res.data[reqData]['properties']
                let obj = {
                    'title': reqData,
                    'name': reqData.replace(/ |\//g, "_"),
                    'type': dataType,
                    'unit': dataUnit,
                    'properties': dataProperties,
                    'value': null
                }
                widgets.push(obj)
            }
        }

        dispatch(setEquipmentType({
            equipmentType,
            equipmentTypeDisplayName,
            siteDisplayName: selectedSite,
            siteName,
            equipmentsTypeDataMapping: equipmentsTypeData,
            widgets: sortAccToPriorityInstantControl(widgets, equipmentType),
            validationConstraint
        }))
    }

    const updateSiteRedux = async (siteName) => {
        let siteID;
        allEquipmentsData.map(obj => {
            if(obj.siteDisplayName === siteName)
                siteID = obj.siteName
        })
        dispatch(setSiteNameControlStatus({
            siteDisplayName: siteName,
            siteName: siteID,
        }))
    }

    const onChangeEquipmentType = equipmentType => {
        unFocus(equipemntSelect.current)
        if(equipmentType){
            setSelectedEquipmentType(equipmentType)

            if(selectedSite && equipmentType)
                updateEquipmentTypeRedux(equipmentType)
        }
    }

    const onChangeSites = siteName => {
        if (siteName) {
            let tempEquipmentData = [];
            let requiredData = siteEquipmentTypeMapping
            tempEquipmentData = requiredData[siteName].sort(function (a, b) {
                let textOne = typeof a.displayName === 'string' ?
                    a.displayName.toLowerCase() : a.displayName.toString();
                let textSecond = typeof b.displayName === 'string' ?
                    b.displayName.toLowerCase() : b.displayName.toString();
                textOne = textOne.trim()
                textSecond = textSecond.trim()
                return textOne.localeCompare(textSecond);
            });
            unFocus(siteSelect.current)
            setSelectedSite(siteName)
            setEquipmentsTypeData(tempEquipmentData)
            setSelectedEquipmentType(null)

            if(props.controlStatus)
                updateSiteRedux(siteName)
            
        }
    };

    return (
        <div className="d jc-sb hierarchy-context mb-10 mt-20" style={{margin: props.controlStatus ? '15px 0' : '10px'}}>
            <div>
                <span id={"custom-select-label"}>{getTranslation("Site")}</span>
                <Select
                    ref={siteSelect}
                    showSearch
                    placeholder={getTranslation("Select Site")}
                    onChange={onChangeSites}
                    suffixIcon={
                    <img src={DownArrowOutlined} alt="down-arrow" width={"14px"} />
                    }
                    value={selectedSite}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ marginRight: props.controlStatus ? "0" : "10px" }}
                >
                    {!_isEmpty(siteEquipmentTypeMapping) &&
                    Object.keys(siteEquipmentTypeMapping).map((site) => (
                        <Select.Option key={site} value={site}>
                        {site}
                        </Select.Option>
                    ))}
                </Select>
            </div>
            {
                !props.controlStatus && 
                <div>
                    <span id={"custom-select-label"}>{getTranslation("Type")}</span>
                    <Select
                        ref={equipemntSelect}
                        showSearch
                        value={selectedEquipmentType}
                        onChange={onChangeEquipmentType}
                        suffixIcon={<img src={DownArrowOutlined} alt="down-arrow" width={'14px'} />}
                        placeholder={getTranslation("Select Equipment Type")}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{width: '225px'}}>
                            {equipmentsTypeData.length !== 0 && equipmentsTypeData.map(equipment => <Select.Option value={equipment.name} key={equipment.name} >{getTranslation(equipment.displayName)}</Select.Option>)}
                    </Select>
                </div>
            }
        </div>
    )
}
