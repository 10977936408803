import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react'
import { DeleteOutlined, ExclamationOutlined, FilterOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FilterContent } from './filters';
import ReactDataSheet from 'react-datasheet'
import { updateState } from '../../actions/bulkActions';
import { getValid } from '../../lib/softMapping';
import { useTranslation } from '../../context/translationContext';


export const ComponentSheet = forwardRef(({ gridData, freqId, timeFrameId }, ref) => {
    const { getTranslation } = useTranslation();

    const makeColumnFixed = () => {
        let firstRow = document.querySelectorAll(".cs-sheet table tr:first-child td")
        for (let index = 0; index < firstRow.length; index++) {
            const element = firstRow[index];
            element.classList.add('top-header')
        }

        let secondRow = document.querySelectorAll(".cs-sheet table tr:nth-child(2) td")
        for (let index = 0; index < secondRow.length; index++) {
            const element = secondRow[index];
            element.classList.add('second-header')
        }

        let firstCol = document.querySelectorAll(".cs-sheet table tr td:first-child")
        for (let index = 2; index < firstCol.length; index++) {
            const element = firstCol[index];
            element.classList.add('left-header')
        }

        let left = "100px"
        if (firstCol[2])
            left = `${firstCol[2].getBoundingClientRect().width}px`;

        let secondCol = document.querySelectorAll(".cs-sheet table tr td:nth-child(2)")
        for (let index = 2; index < secondCol.length; index++) {
            const element = secondCol[index];
            element.classList.add('second-left-header')
            element.style.left = left;
        }

        let secondHeader = document.querySelector(".top-header:nth-child(2)")
        if (secondHeader) {
            secondHeader.style.left = left
            secondHeader.style.zIndex = 26;
        }
    }


    function isInViewport(el) {
        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            top >= window.pageYOffset &&
            left >= window.pageXOffset &&
            (top + height) <= (window.pageYOffset + window.innerHeight) &&
            (left + width) <= (window.pageXOffset + window.innerWidth)
        );
    }

    function makeScroll(value) {
        let newRow = document.querySelector(".selected");
        if (newRow) {
            let parentElement = document.querySelector('.cs-sheet');
            let scrollValue = parentElement.scrollTop;
            let rect = newRow.getBoundingClientRect();

            if (!isInViewport(newRow)) {
                if (value === "up") {
                    scrollValue -= rect.height;
                    if (scrollValue <= 0)
                        scrollValue = 0;
                    parentElement.scrollTop = scrollValue
                } else if (value === "down") {
                    scrollValue += rect.height;
                    parentElement.scrollTop = scrollValue
                } else if (value === "right") {
                    let scrollValue = parentElement.scrollLeft;
                    scrollValue += rect.width;
                    parentElement.scrollLeft = scrollValue
                }
                else {
                    let scrollValue = parentElement.scrollLeft;
                    scrollValue -= rect.width;
                    if (scrollValue <= 0)
                        scrollValue = 0;
                    parentElement.scrollLeft = scrollValue
                }
            }
        }
        return false
    }

    const [grid, setgrid] = useState(null);
    const [equipments, setEquipments] = useState([]);

    const childRef = useRef();

    const bulkReducer = useSelector(state => state.bulkReducer)
    const dispatch = useDispatch();

    const checkFilterData = () => bulkReducer.filterData && bulkReducer.filterData[`${timeFrameId}__${freqId}`]

    useEffect(() => {
        if (checkFilterData()) {
            setgrid(bulkReducer.filterData[`${timeFrameId}__${freqId}`]);
        } else {
            if (gridData) {
                setgrid(gridData);
            } else {
                const grid = generateGrid();
                addWidget(grid)
                // setgrid(grid);
            }
        }

        document.addEventListener("keydown", (event) => {
            switch (event.key) {
                //arrow down
                case "ArrowLeft":
                    makeScroll("left");
                    return false;
                case "ArrowRight":
                    makeScroll("right");
                    return false
                case "ArrowUp":
                    makeScroll("up");
                    return false
                case "ArrowDown":
                    makeScroll("down");
                    return false
                default:
                    break
            }
        });

        makeColumnFixed()

    }, [gridData, bulkReducer.filterData])

    useEffect(() => {
        if (childRef.current) {
            if (grid && !checkFilterData()) {
                dispatch(updateState({ newState: grid, freqId, key: timeFrameId }))
            }
        } else childRef.current = true
    }, [grid])

    const rowFilter = (name) => {
        return (
            <div className="d jc-sa" style={{ padding: 5 }}  > {name}
                <Popover className="curve" placement="bottomLeft" overlayClassName="new-ui cs-freq-select"
                    content={<FilterContent freqId={freqId} timeFrameId={timeFrameId} name={name} callBack={() => { }} />} trigger="click">
                    <div className="" style={{ cursor: 'pointer' }}>
                        {/* <FilterOutlined /> */}
                    </div>
                </Popover>
            </div>
        )
    }

    const onDelete = (eventId) => {
        let tempGrid = grid;
        let eventIdPos = tempGrid[0].findIndex(item=> item.eventId === eventId);

        if(eventIdPos === -1){
            return;
        }

        tempGrid[0].splice(eventIdPos, 1)
       
        let totalStreams = bulkReducer.widgets.length;
        tempGrid[1].splice(0 , totalStreams)

        let streamStart = (eventIdPos - 3) *  totalStreams + 3;

        for (let rowCount = 2; rowCount < grid.length; rowCount++) {
            tempGrid[rowCount].splice(streamStart, totalStreams)
        }
 
        for (let columnCount = 0; columnCount < tempGrid[0].length; columnCount++) {
            tempGrid[0][columnCount].eventPos =  columnCount;
        }

        dispatch(updateState({ newState: tempGrid, freqId, key: timeFrameId }))
    }

    const eventTitle = (name, eventId, eventStart) => {
        return (
            <div className="d jc-sa" style={{ padding: 5 }}  > {name}
                {eventStart !== 3 && <div className="" style={{ cursor: 'pointer' }}
                    onClick={() => onDelete(eventId)}>
                    <DeleteOutlined />
                </div>}
            </div>
        )
    }

    const streamTitle = (name) => {
        let validValue = getValid(bulkReducer.validationConstraint);
        let content = () => <div>
            {getTranslation("24 hour format", 'bulkControlSheet')}
        </div>

        if (validValue[name]) {
          if (validValue[name][0] === 'Discrete') {
            content = () =>
              validValue[name].map(
                (name, idx) =>
                  idx !== 0 && (
                    <div className="display-column">
                      {/* added context of 'bulkControlSheet' for specific lookup in language json mapping */}
                      {getTranslation(name, 'bulkControlSheet')}
                    </div>
                  )
              );
          } else {
            content = () => (
              <div className="display-column">
                {getTranslation('Min', 'bulkControlSheet')}: {validValue[name]?.min_measured || 0}
                <br />
                {getTranslation('Max', 'bulkControlSheet')}: {validValue[name]?.max_measured || 0}
              </div>
            );
          }
        }

        return (
            <div key={name} className="d jc-sa ai-center" style={{ padding: 5 }} >
                <Popover content={content} title={null}>
                    {/* added context of 'bulkControlSheet' for specific lookup in language json mapping */}
                    {getTranslation(name, 'bulkControlSheet')}
                    {/* <ExclamationOutlined style={{ cursor: 'pointer' }} /> */}
                </Popover>
            </div>
        )
    }

    const generateGrid = () => {
        let rows = [
            [
                {
                    readOnly: true, colSpan: 1, rowSpan: 2, component: rowFilter(getTranslation("Region", 'bulkControlSheet')),
                    forceComponent: true, disableEvents: true
                },
                {
                    readOnly: true, colSpan: 1, rowSpan: 2, component: rowFilter(getTranslation("Site", 'bulkControlSheet')),
                    forceComponent: true, disableEvents: true
                },
                {
                    readOnly: true, colSpan: 1, rowSpan: 2, component: rowFilter(getTranslation("Equipment", 'bulkControlSheet')),
                    forceComponent: true, disableEvents: true
                },
            ], [

            ]
        ]
        rows = rows.concat(bulkReducer.selectedEquipmentsMapping.map((data, id) =>
            [{ value: data.region }, { value: data.siteDisplayName }, { value: data.displayName }]))
        return rows
    }

    const addWidget = (rows) => {
        let tempGrid = grid
        if (rows) {
            tempGrid = rows
        }
        let eventPos = null
        let eventId = Date.now();
        let totalStreams = bulkReducer.widgets.length
        eventPos = tempGrid[0].length;
        
        tempGrid[0].push({
            readOnly: true, colSpan: totalStreams, component: eventTitle(getTranslation("Event", 'bulkControlSheet'), eventId, eventPos), eventId,
            forceComponent: true, disableEvents: true
        });
        tempGrid[1].push(...bulkReducer.widgets.map(stream => {
            return {
                component: streamTitle(stream), forceComponent: true,
                colSpan: 1, readOnly: true, disableEvents: true, value: stream
            }
        }));
        for (let row = 2; row < tempGrid.length; row++) {
            let values = new Array(totalStreams)
            tempGrid[row].push(...values.fill("").map(data => {
                return {
                    value: data,
                }
            }))
        }
        setgrid([...tempGrid])
    }

    useImperativeHandle(ref, () => ({ addWidget }))

    const onCellsChanged = changes => {
        const Tempgrid = grid;
        changes.forEach(({ cell, row, col, value }) => {
            Tempgrid[row][col] = { ...Tempgrid[row][col], value };
        });
        setgrid([...Tempgrid])
    };

    return (
        grid &&
        <div>
            {/* added context of 'bulkControlSheet' for specific lookup in language json mapping */}
            <ReactDataSheet
                data={grid}
                onSelect={() => makeColumnFixed()}
                valueRenderer={(cell) => getTranslation(cell.value, 'bulkControlSheet')}
                onCellsChanged={onCellsChanged}
            />
        </div>
    )
})